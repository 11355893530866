.wrapper {
  display: flex;
  flex-direction: column;
}

.mainLabel {
  color: rgb(2, 11, 136);
}

.content {
  display: flex;
  justify-content: center;
}

.detail {
  width: 400px !important;
}

.label {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: 10px;
}

.value {
  flex: 2;
}

.map {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

.backButton {
  display: flex;
  justify-content: center;
}
