.wrapper {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 50px;
  justify-content: center;
}

.content {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 10px;
  width: 70%;
}

.field {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.title {
  margin: 10px;
}

.input {
  display: flex;
}
