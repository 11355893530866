.wrapper {
  display: flex;
  margin-bottom: 5px;
  height: 40px;
  border-bottom: 1px solid grey;
  justify-content: center;
}

.content {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}
